import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";

// CORE
const Home = React.lazy(() => import("./pages/Core/Home/Index"));
const Regulamin = React.lazy(() => import("./pages/Core/Regulamin/Index"));
const Aktualnosc = React.lazy(() => import("./pages/Core/Aktualnosc/Index"));
const Nagroda = React.lazy(() => import("./pages/Core/Nagroda/Index"));
const FirstLogin = React.lazy(() => import("./pages/Core/FirstLogin/Index.js"));
// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
// const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ResetPassword = React.lazy(() =>
  import("./pages/Accounts/ResetPassword/Index.js")
);
const ForgetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgetPassword/Index.js")
);
// UserPanel
// const Panel = React.lazy(() => import("./pages/Panel/Home/Index.js"));

// Admin Panel
const Admin = React.lazy(() => import("./pages/Admin/Home/Index.js"));
const AdminRaporty = React.lazy(() => import("./pages/Admin/Raporty/Index.js"));
const AdminNagrody = React.lazy(() => import("./pages/Admin/Nagrody/Index.js"));
const AdminNagrodaDodaj = React.lazy(() =>
  import("./pages/Admin/AddNagroda/Index.js")
);
const AdminNagroda = React.lazy(() => import("./pages/Admin/Nagroda/Index.js"));
const AdminGrupy = React.lazy(() => import("./pages/Admin/Grupy/Index.js"));
const AdminAktualnosci = React.lazy(() =>
  import("./pages/Admin/Aktualnosci/Index.js")
);
const AdminAktualnosciDodaj = React.lazy(() =>
  import("./pages/Admin/AddAktualnosc/Index.js")
);
const AdminAktualnosc = React.lazy(() =>
  import("./pages/Admin/Aktualnosc/Index.js")
);
const AdminUserAdd = React.lazy(() =>
  import("./pages/Admin/AddUser/AddUser.js")
);
const AdminUser = React.lazy(() => import("./pages/Admin/User/Index.js"));
const AdminGrupa = React.lazy(() => import("./pages/Admin/Grupa/Index.js"));
const AdminAddGrupa = React.lazy(() =>
  import("./pages/Admin/AddGrupa/Index.js")
);
const AdminTerminy = React.lazy(() => import("./pages/Admin/Terminy/Index.js"));
const AdminTerminMecze = React.lazy(() =>
  import("./pages/Admin/TerminMecze/Index.js")
);
const AdminSMS = React.lazy(() => import("./pages/Admin/AdminSMS/Index.js"));

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/przypomnij-haslo" element={<ForgetPassword />} />
          <Route path="/zmien-haslo" element={<ResetPassword />} />
          <Route path="/regulamin" element={<Regulamin />} />

          {authCtx.isLoggedIn && (
            <>
              {/* ADMIN */}
              <Route index element={<Home />} />
              <Route path="/podaj-dane" element={<FirstLogin />} />

              <Route path="/aktualnosc/:news_id" element={<Aktualnosc />} />
              <Route path="/nagroda/:news_id" element={<Nagroda />} />
              <Route path="/admin" element={<Admin />} />
              <Route
                path="/admin/uzytkownik/dodaj"
                element={<AdminUserAdd />}
              />
              <Route
                path="/admin/uzytkownik/:user_id"
                element={<AdminUser />}
              />
              <Route path="/admin/grupy" element={<AdminGrupy />} />
              <Route path="/admin/raporty" element={<AdminRaporty />} />
              <Route path="/admin/aktualnosci" element={<AdminAktualnosci />} />
              <Route
                path="/admin/aktualnosci/dodaj"
                element={<AdminAktualnosciDodaj />}
              />
              <Route
                path="/admin/aktualnosci/:news_id"
                element={<AdminAktualnosc />}
              />
              <Route path="/admin/nagrody" element={<AdminNagrody />} />
              <Route
                path="/admin/nagrody/dodaj"
                element={<AdminNagrodaDodaj />}
              />
              <Route
                path="/admin/nagrody/:nagroda_id"
                element={<AdminNagroda />}
              />
              <Route path="/admin/grupy/dodaj" element={<AdminAddGrupa />} />
              <Route path="/admin/grupy/:group_id" element={<AdminGrupa />} />
              <Route path="/admin/terminy" element={<AdminTerminy />} />
              <Route
                path="/admin/terminy/:termin_id"
                element={<AdminTerminMecze />}
              />
              <Route path="/admin/sms" element={<AdminSMS />} />
            </>
          )}
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
